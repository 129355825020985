<template>
  <div class="background full support">
    <v-container class="headContainer">
      <burger_menu />
      <de_header />
    </v-container>
    <v-container class="supportContainer">
      <v-row
        justify="center"
        class="categoriesRowsHead"
        no-gutters
        v-if="showGeneral"
      >
        <v-col
          class="categories"
          v-for="item in categories"
          :key="item"
          cols="12"
          sm="3"
        >
          <v-btn @click="categorySelection(item, 0)" :class="item.class">
            <v-container class="categoryContainer">
              <v-row justify="center" class="supportIcon">
                <v-icon dark size="40"> {{ "mdi-" + item.icon }} </v-icon>
              </v-row>
              <v-card class="categoryText" tile>
                {{ item.name }}
              </v-card>
            </v-container>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card
      class="mx-auto supportDivider"
      color="#242422"
      elevation="0"
      height="0.3vh"
      width="100vw"
      v-if="showSubcategories"
    />
    <v-container class="supportContainer">
      <v-row
        justify="center"
        class="categoriesRows"
        no-gutters
        v-if="showSubcategories"
      >
        <v-col
          class="categories"
          v-for="item in selectedSubcategories"
          :key="item"
          cols="12"
          sm="3"
        >
          <v-btn
            @click="categorySelection(item, 1)"
            :class="item.class"
            hover="false"
          >
            <v-container class="categoryContainer">
              <v-row justify="center" class="supportIcon">
                <v-icon dark size="40"> {{ "mdi-" + item.icon }} </v-icon>
              </v-row>
              <v-card class="categoryText" justify="center" tile>
                {{ item.name }}
              </v-card>
            </v-container>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card
      class="mx-auto supportDivider"
      color="#242422"
      elevation="0"
      height="0.4vh"
      width="100vw"
      v-if="showCollections"
    />
    <v-container class="articleContainer">
      <v-row
        justify="center"
        class="categoriesRowsCollection"
        v-if="showCollections"
      >
        <v-col
          class="categories"
          v-for="item in selectedCollections"
          :key="item"
          cols="12"
          sm="3"
        >
          <v-btn @click="categorySelection(item, 2)" :class="item.class">
            <v-card class="categoryCollection" justify="center" tile>
              {{ item.name }}
            </v-card>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <de_footer />
    <v-snackbar
      dark
      color="green darken-2"
      id="snack"
      v-model="snackbar"
      :timeout="2000"
      >{{ snackTxt }}</v-snackbar
    >
  </div>
</template>

<script>
import burger_menu from "../components/BurgerMenu.vue";
import de_header from "../components/Header.vue";
import de_footer from "../components/Footer.vue";
import ImportedCategories from "../help/categories.json";
import ImportedSubcategories from "../help/subcategories.json";
import ImportedCollections from "../help/collections.json";

export default {
  name: "Support",

  components: {
    burger_menu,
    de_header,
    de_footer,
  },

  data: () => ({
    snackbar: "",
    snackTxt: "",
    showGeneral: true,
    showSubcategories: false,
    showCollections: false,
    showArticle: false,
    items: [],
    categories: null,
    subcategories: null,
    collections: null,
    articles: null,
    selectedSubcategories: [],
    selectedArticle: null,
    selectedCollections: [],
  }),

  created() {
    this.categories = ImportedCategories;
    this.subcategories = ImportedSubcategories;
    this.collections = ImportedCollections;

    this.categories.forEach((element) => {
      element["class"] = "supportButtonHead";
    });
    this.subcategories.forEach((element) => {
      element["class"] = "supportButton";
    });
    this.collections.forEach((element) => {
      element["class"] = "supportButtonCollection";
    });
  },

  methods: {
    categorySelection(item, layer) {
      if (layer == 0) {
        this.showSubcategories = true;
        this.showCollections = false;
        this.selectedSubcategories = [];
        this.selectedCollections = [];
        this.selectedArticle = null;
        item.class = "supportButtonHead supportButtonHeadActive";

        this.categories.forEach((element) => {
          if (element != item) {
            element.class = "supportButtonHead";
          }
        });
        this.resetSubcategories(item);
        this.resetCollection(item);
        this.$forceUpdate();
      }
      if (layer == 1) {
        if (item.action == "collection") {
          this.showCollections = true;
        } else {
          this.showCollections = false;
        }

        this.selectedCollections = [];
        this.selectedArticle = null;
        item.class = "supportButton supportButtonActive";

        this.resetSubcategories(item);
        this.resetCollection(item);
        this.$forceUpdate();
      }
      if (layer == 2) {
        this.selectedArticle = null;
        item.class = "supportButtonCollection supportButtonCollectionActive";

        this.resetCollection(item);
        this.$forceUpdate();
      }

      if (item.action == "collection") {
        this.showArticle = false;

        for (let i = 0; i < this.subcategories.length; i++) {
          if (this.subcategories[i].parent == item.name) {
            this.selectedSubcategories.push(this.subcategories[i]);
            this.items[0] = { text: item.name, disabled: false };
          }
        }
        for (let i = 0; i < this.collections.length; i++) {
          if (this.collections[i].parent == item.name) {
            this.selectedCollections.push(this.collections[i]);
            this.items[1] = { text: item.name, disabled: false };
          }
        }
      } else {
        if (item.action == "ticket") {
          if (item.name != "Not listed") {
            this.$router.resolve({
              name: "Bug-Report",
              query: { topic: item.name },
            }).href;
          } else {
            // Use the parent as the topic, instead of the name
            this.$router.push({
              name: "Bug-Report",
              query: {
                topic:
                  item.parent.charAt(0) + item.parent.slice(1).toLowerCase(),
              },
            });
          }
        } else if (item.action == "feedback") {
          this.$router.push({ name: "Home", hash: "#contact" });
        } else if (item.action == "banAppeal") {
          this.$router.push({ name: "Ban-Appeal" });
        } else if (item.action == "deleteAccount") {
          this.$router.push({ name: "Account-Deletion" });
        } else {
          this.selectedArticle = item.article;
          this.$router.push({ name: this.selectedArticle }).catch(() => {});
        }
      }
    },

    resetSubcategories(item) {
      this.selectedSubcategories.forEach((element) => {
        if (element != item) {
          element.class = "supportButton";
        }
      });
      this.subcategories.forEach((element) => {
        if (element != item) {
          element.class = "supportButton";
        }
      });
    },

    resetCollection(item) {
      this.selectedCollections.forEach((element) => {
        if (element != item) {
          element.class = "supportButtonCollection";
        }
      });
      this.collections.forEach((element) => {
        if (element != item) {
          element.class = "supportButtonCollection";
        }
      });
    },

    resetSearch() {
      this.showGeneral = true;
      this.showReset = false;
      this.showArticle = false;
      this.selectedArticle = null;
      this.items = [];
    },
  },
};
</script>
