<template>
  <div>
    <p class="background home">
      <v-container class="headContainer">
        <burger_menu />
        <de_header />
        <social showFunding="true"/>
      </v-container>
      <iframe
        class="videoSpacer"
        :width="videoWidth"
        :height="videoHeight"
        src="https://www.youtube.com/embed/W9gqGfIN0UM?vq=hd1080"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </p>
    <v-card
      class="mx-auto"
      color="#242422"
      elevation="0"
      height="5vh"
      width="100vw"
    />
    <p class="background game" id="game">
      <iframe
        src="https://store.steampowered.com/widget/1088150/"
        :width="widgetWidth"
        :height="widgetSteamHeight"
        style="border: none"
        scrolling="no"
        frameborder="0"
        class="widget-top"
      ></iframe>

      <v-sheet
        v-if="!isMobile"
        color="transparent"
        class="mx-auto"
        elevation="0"
        max-width="1100"
      >
        <v-expand-transition>
          <v-sheet color="transparent" height="50vh" tile>
            <v-row class="fill-height" align="center" justify="center">
              <v-img :src="getImg(model)" height="50vh" contain />
            </v-row>
          </v-sheet>
        </v-expand-transition>
        <v-slide-group
          v-model="model"
          class="backgroundMiddle"
          mandatory
          center-active
          show-arrows
        >
          <v-slide-item
            v-for="n in images.length"
            :key="n"
            v-slot:default="{ toggle }"
          >
            <v-card
              elevation="6"
              color="transparent"
              class="ma-4"
              width="15vw"
              @click="toggle"
            >
              <v-img :src="getImg(n - 1)" contain />
              <v-row class="fill-height" align="center" justify="center">
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <v-carousel
        v-if="isMobile"
        :width="imageWidth"
        :height="imageHeight"
        hide-delimiter-background
        hide-delimiters
      >
        <v-carousel-item
          v-for="(item, i) in images"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-img :src="images[i]" contain />
        </v-carousel-item>
      </v-carousel>
      <v-container class="containerText">
        <v-row justify="center" no-gutters>
          <v-col md="7">
            <v-card class="pa-2" outlined color="transparent"
              >Adrenaline fueled, fast paced drawing action. Draw fast, type
              faster and win it all! THOUSANDS of official words in 7 different
              languages + INFINITELY more from the community. 4 multiplayer game
              modes and a singleplayer mode. We take you back to the heated
              pictionary games everyone had in school!</v-card
            >
          </v-col>
        </v-row>
      </v-container>
    </p>
    <v-card
      class="mx-auto"
      color="#242422"
      elevation="0"
      height="5vh"
      width="100vw"
    />
    <p class="background contact" id="contact">
      <v-container>
        <v-row class="mb-6" justify="center" no-gutters>
          <h2>GET IN TOUCH WITH THE DEV</h2>
        </v-row>
        <v-row class="mb-6" justify="center" no-gutters>
          <v-text-field
            v-model="email"
            label="E-Mail address"
            autocomplete="email"
            outlined
            class="textBox"
          ></v-text-field>
        </v-row>
        <v-row class="mb-6" justify="center" no-gutters>
          <v-text-field
            v-model="name"
            label="Your name"
            outlined
            class="textBox"
          ></v-text-field>
        </v-row>
        <v-row class="mb-6" justify="center" no-gutters>
          <v-textarea
            class="textBox"
            outlined
            label="Message"
            v-model="message"
            counter
            maxlength="2000"
          ></v-textarea>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-btn class="ma-2" outlined @click="ticket">Submit</v-btn>
        </v-row>
      </v-container>
      <de_footer />
    </p>
    <v-snackbar
      dark
      :color="snackColor"
      id="snack"
      v-model="snackbar"
      :timeout="2000"
      >{{ snackTxt }}</v-snackbar
    >
  </div>
</template>

<script>
import axios from "axios";
import validator from "email-validator";

import burger_menu from "../components/BurgerMenu.vue";
import social from "../components/SocialButtons.vue";
import de_header from "../components/Header.vue";
import de_footer from "../components/Footer.vue";

export default {
  name: "LandingPage",

  components: {
    burger_menu,
    social,
    de_header,
    de_footer,
  },

  data: () => ({
    toggle: 3,
    model: 0,
    images: [
      require("../assets/store_images/screenshot_store_01.png"),
      require("../assets/store_images/screenshot_store_02.png"),
      require("../assets/store_images/screenshot_store_03.png"),
      require("../assets/store_images/screenshot_store_04.png"),
      require("../assets/store_images/screenshot_store_05.png"),
      require("../assets/store_images/screenshot_store_06.png"),
      require("../assets/store_images/screenshot_store_07.png"),
      require("../assets/store_images/screenshot_store_08.png"),
      require("../assets/store_images/screenshot_store_09.png"),
      require("../assets/store_images/screenshot_store_10.png"),
    ],
    isMobile: false,
    videoWidth: 1008,
    videoHeight: 567,
    widgetWidth: 700,
    widgetSteamHeight: 190,
    imageWidth: 200,
    imageHeight: 0,

    email: "",
    name: "",
    message: "",

    snackbar: false,
    snackColor: "",
    snackTxt: "",
  }),

  created() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    ticket() {
      if (
        validator.validate(this.email) &&
        this.name != "" &&
        this.message != ""
      ) {
        axios({
          method: "post",
          url:
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/api/Ticket/Submit",
          data: {
            subject: "open-question",
            sender_email: this.email,
            sender_name: this.name,
            message: this.message,
          },
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => {
            if (response.status == 200) {
              this.showTicketSuccess();
            } else {
              this.showTicketSendFailure("Error " + response.status);
            }
          })
          .catch((reason) => {
            this.showTicketSendFailure(reason);
          });
      } else {
        this.showTicketValidateFailure();
      }
    },

    showTicketSuccess() {
      this.email = "";
      this.name = "";
      this.message = "";

      this.snackTxt = "Ticket successfully send!";
      this.snackColor = "green darken-2";
      this.snackbar = true;
    },

    showTicketValidateFailure() {
      if (validator.validate(this.email) == false) {
        this.showTicketFailure("Invalid E-Mail address...");
      } else if (this.name == "") {
        this.showTicketFailure("Enter a name...");
      } else {
        this.showTicketFailure("Enter a message...");
      }
    },

    showTicketSendFailure(reason) {
      this.showTicketFailure("Failed to send ticket. " + reason);
    },

    showTicketFailure(txt) {
      this.snackTxt = txt;
      this.snackColor = "red darken-2";
      this.snackbar = true;
    },

    handleResize() {
      if (window.innerWidth < 801) {
        this.isMobile = true;
        this.widgetWidth = window.innerWidth * 0.9;
        this.widgetSteamHeight = 200;
        this.imageWidth = window.innerWidth;
        this.imageHeight = this.imageWidth * (9 / 16);
      } else {
        this.isMobile = false;
        this.widgetWidth = 700;
        this.widgetSteamHeight = 190;
      }

      if (window.innerWidth < 1261) {
        this.videoWidth = window.innerWidth * 0.9;
      } else {
        this.videoWidth = 1000;
      }

      this.videoHeight = this.videoWidth * (9 / 16);
    },

    getImg(n) {
      return this.images[n];
    },
  },
};
</script>
